<template>
    <section>
        <InsideHeader :title="pageData.page_heading" :bredcrumb="breadCrumb" />  
        <div class="section fofum_wrap forum_details">
            <div class="container">
                <div class="row section-big-pb-space">
                    <div class="col-sm-12 blog-detail">
                        <div class="creative-card">
                            <img :src="filePath+'/'+blogData.image_name" class="img-fluid blog-image" :alt="blogData.title">
                            <div class="mt-3">
                                <h1>{{ blogData.title }}</h1>
                                <ul class="post-social">
                                    <li><i class="icon-material-outline-date-range"></i> {{ blogData.post_date }}</li>
                                </ul>
                                <div v-html="blogData.content"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>


<script>
import InsideHeader from "@/components/InsideHeader.vue";
import Translation from '../../../public/translation.json'

export default {
    components: {InsideHeader},
    data(){
        return {
            breadcrumb: [],
            blogData: [],
            pageData: [],
            forumId: this.$route.params.id,
            defaultLang: localStorage.getItem('_store_lang') || 'en',
            filePath: ''
        }
    },    
    computed: {
        breadCrumb(){
            return this.breadcrumb = [
                {title: this.pageContent('home'), path:'/'},
                {title: this.pageContent('blog'), path:'#'}
            ]
        }
    },
    methods: {
         pageContent(field){
            return Translation.content[this.defaultLang][field]
        },
        getPageData() {
            let slug = 'blog'
            this.$store.dispatch('getPageData', slug).then(res=>{
               if(res.data.status === true) {
                    this.pageData = res.data.data
                }
            })
            .catch(err => console.log(err))
        },
        getSingleBlog() {
            let headers = {},
                endPoint = `blog/${this.forumId}/blog-details`;
                       
            this.$http.get(endPoint, headers).then(res=> {
                if(res.data.status === true) {
                    this.blogData = res.data.data
                    if(res.data.file_path)
                        this.filePath = res.data.file_path
                }
            })
            .catch(err => console.log(err))
        }
    },
    created: function(){
        this.getPageData()
        this.getSingleBlog()
    },
    
}
</script>